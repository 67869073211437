const dev = {
	STRIPE_KEY: "pk_test_51HUCTQHcjNz0J1rjw0i0N6OtQKyPrnyaMgjOlUSQMc6keXw6BXYYDchEFw0eotI7OvFXCxfBooseWqfVUloclhVy00arvJuViH",
	s3: {
		REGION: "us-east-2",
		BUCKET: "dev-notes-infra-s3-uploads4f6eb0fd-2yi76bagpt8z"
	},
	apiGateway: {
		REGION: "us-east-2",
		URL: "https://3itpgnnukd.execute-api.us-east-2.amazonaws.com/dev"
	},
	cognito: {
		REGION: "us-east-2",
		USER_POOL_ID: "us-east-2_WleTLKKub",
		APP_CLIENT_ID: "4h106jr9qor0htoe2l3e8udnvt",
		IDENTITY_POOL_ID: "us-east-2:3aa333c9-7cbf-4583-8d95-c040f7d7ede5"
	}
};

const prod = {
	STRIPE_KEY: "pk_test_51HUCTQHcjNz0J1rjw0i0N6OtQKyPrnyaMgjOlUSQMc6keXw6BXYYDchEFw0eotI7OvFXCxfBooseWqfVUloclhVy00arvJuViH",
	s3: {
		REGION: "us-east-2",
		BUCKET: "prod-notes-infra-s3-uploads4f6eb0fd-1yg0vfv72lfi"
	},
	apiGateway: {
		REGION: "us-east-2",
		URL: "https://imyxgu51rb.execute-api.us-east-2.amazonaws.com/prod"
	},
	cognito: {
		REGION: "us-east-2",
		USER_POOL_ID: "us-east-2_tyup2ZejH",
		APP_CLIENT_ID: "2tcbq04tr0ke0irlc7o8qhbeah",
		IDENTITY_POOL_ID: "us-east-2:e04493bd-127b-4247-b830-355eb4878d5e"
	}
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
	? prod : dev;

export default {
	// Add common config values here
	MAX_ATTACHMENT_SIZE: 5000000,
	...config
};
